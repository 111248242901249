import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';

import { AppComponent } from './app.component';
import { NavbarComponent } from './shared/navbar/navbar.component';

import { HomeModule } from './home/home.module';
import { CountdownTimerModule } from 'ngx-countdown-timer';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { PolicyComponent } from './policy/policy.component';
import { TermsComponent } from './terms/terms.component';
import { RefundpolicyComponent } from './refundpolicy/refundpolicy.component';
import { FooterComponent } from './shared/footer/footer.component';
import { AngularFireModule } from 'angularfire2';
import { AngularFirestore } from '@angular/fire/firestore';

var firebaseConfig = {
    apiKey: "AIzaSyC-nfrx5TQRtATE4HolT0eoVwKrx2PwJAE",
    authDomain: "flowerclick-e7ee7.firebaseapp.com",
    databaseURL: "https://flowerclick-e7ee7.firebaseio.com",
    projectId: "flowerclick-e7ee7",
    storageBucket: "flowerclick-e7ee7.appspot.com",
    messagingSenderId: "191762464374",
    appId: "1:191762464374:web:0e486f2154e5c77fbf2957",
    measurementId: "G-1PKHPMJ87B"
  };

@NgModule({
    declarations: [
        AppComponent,
        NavbarComponent,
        AboutComponent,
        ContactComponent,
        PolicyComponent,
        TermsComponent,
        RefundpolicyComponent,
        FooterComponent,
    ],
    imports: [
        BrowserAnimationsModule,
        NgbModule,
        FormsModule,
        RouterModule,
        AppRoutingModule,
        HomeModule,  
        CountdownTimerModule.forRoot(),
        ReactiveFormsModule,
        AngularFireModule.initializeApp(firebaseConfig)
    ],
    providers: [AngularFirestore],
    bootstrap: [AppComponent]
})
export class AppModule { }
