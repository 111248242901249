import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { PolicyComponent } from './policy/policy.component';
import { TermsComponent } from './terms/terms.component';
import { RefundpolicyComponent } from './refundpolicy/refundpolicy.component';
const routes: Routes =[
    { path: 'home',component: HomeComponent},
    { path: 'about',component:AboutComponent},
    { path: 'contact',component:ContactComponent},
    { path: 'privacystatement',component:PolicyComponent},
    { path: 'terms',component:TermsComponent},
    { path: 'return',component:RefundpolicyComponent},
    { path: '', redirectTo: 'home', pathMatch: 'full' }
];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes,{
          useHash: true
        })
    ],
    exports: [
    ],
})
export class AppRoutingModule { }
