import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

 
 model: any = {};

 constructor( private af: AngularFirestore,private formBuilder: FormBuilder) { }


ngOnInit() {

    var body = document.getElementsByTagName('body')[0];
    body.classList.add('contact-page');
    var navbar = document.getElementsByTagName('nav')[0];
    navbar.classList.add('navbar-transparent');
}
mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";
form : FormGroup;
submitted = false;

todayDate : Date = new Date();
onSubmit() {
  var ob = this.af.collection('contactsForms').add(
    {
      firstname:this.model.firstName,
      lastname:this.model.lastName,
      contact:this.model.contact,
      email:this.model.email,
      message:this.model.message,
      date:this.todayDate,
      
    }
  );
  this.model={};    
  alert('Thank you for Contacting us..\n You are very important to us.\n We will contact you as soon as we review your message.')
  
}
ngOnDestroy(){
    var body = document.getElementsByTagName('body')[0];
    body.classList.remove('contact-page');
    var navbar = document.getElementsByTagName('nav')[0];
    navbar.classList.remove('navbar-transparent');
    // navbar.classList.add('navbar-transparent');

}

}
