import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit,OnDestroy {
  constructor() { }

  ngOnInit() {
      var body = document.getElementsByTagName('body')[0];
      body.classList.add('about-us');
      var navbar = document.getElementsByTagName('nav')[0];
      navbar.classList.add('navbar-transparent'); 
  }
  ngOnDestroy(){
      var body = document.getElementsByTagName('body')[0];
      body.classList.remove('about-us');
      var navbar = document.getElementsByTagName('nav')[0];
      body.classList.remove('navbar-transparent');
  }
}
