import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss']
})
export class PolicyComponent implements OnInit{


  constructor() { }

  ngOnInit() {
      
      var body = document.getElementsByTagName('nav')[0];
      body.classList.add('about-us');
      var navbar = document.getElementsByTagName('body')[0];
      navbar.classList.add('about-us');
  }
  
}
